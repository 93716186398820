import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-digital-keypad',
  templateUrl: './digital-keypad.component.html',
  styleUrls: ['./digital-keypad.component.scss']
})
export class DigitalKeypadComponent implements OnInit {

  @Input() value: string = '';
  @Output() valueSelected : EventEmitter<string> = new EventEmitter<string>();
  @Output() onContinue : EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Assigns the selected value to the entire string with the pin
   * @param _value
   * @constructor
   */
  SetPinValue(_value: string){
    this.value += _value
    this.valueSelected.emit(this.value);
  }

  /**
   * Remove the last value from the string with the pin
   * @constructor
   */
  DeleteLastValue(){
    this.value = this.value?.slice(0, -1) ;
    this.valueSelected.emit(this.value);
  }

  /**
   * Outputs a resulting value from the pin
   * @constructor
   */
  Continue(){
    this.onContinue.emit(this.value);
  }

}
