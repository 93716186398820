<div class="grid-container">

  <div class="col-lg-9">
    <div class="grid-container">
    <button class="col-lg-4 key" (click)="SetPinValue('1')">1</button>
    <button class="col-lg-4 key" (click)="SetPinValue('2')">2</button>
    <button class="col-lg-4 key" (click)="SetPinValue('3')">3</button>

    <button class="col-lg-4 key" (click)="SetPinValue('4')">4</button>
    <button class="col-lg-4 key" (click)="SetPinValue('5')">5</button>
    <button class="col-lg-4 key" (click)="SetPinValue('6')">6</button>
    </div>
  </div>
  <button class="col-lg-3 key-options action-key" (click)="DeleteLastValue()"><mat-icon class="material-symbols-outlined">backspace</mat-icon> BORRAR</button>


  <div class="col-lg-9">
    <div class="grid-container">
      <button class="col-lg-4 key" (click)="SetPinValue('7')">7</button>
      <button class="col-lg-4 key" (click)="SetPinValue('8')">8</button>
      <button class="col-lg-4 key" (click)="SetPinValue('9')">9</button>

      <button class="col-lg-4 key" (click)="SetPinValue('0')">0</button>
      <button class="col-lg-4 key" (click)="SetPinValue('.')">.</button>
      <button class="col-lg-4 key" (click)="SetPinValue('00')">00</button>
    </div>
  </div>
  <button class="col-lg-3 key-options action-key large-key" (click)="Continue()">INGRESAR</button>
</div>
